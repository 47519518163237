import { render, staticRenderFns } from "./movements.vue?vue&type=template&id=15e5daff&scoped=true&"
import script from "./movements.vue?vue&type=script&lang=js&"
export * from "./movements.vue?vue&type=script&lang=js&"
import style0 from "./movements.vue?vue&type=style&index=0&id=15e5daff&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e5daff",
  null
  
)

export default component.exports